import {
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { updateUserData } from 'lib/api/preDownload';
import { ChangeEvent, FC, MouseEvent, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  preDownloadImg,
  newXroomLogo,
  XroomIcon,
  LinkedIn,
  SoulxCILogo,
} from '../../static/images/Index';

const initailVal = {
  company: '',
  name: '',
  email: '',
};
const PreDownload: FC = () => {
  const [data, setData] = useState(initailVal);
  const [searchParams] = useSearchParams();
  const fileName = searchParams.get('file');

  const handleInputVal = (e: ChangeEvent<HTMLInputElement>) => {
    setData((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  // const onClickImgLink = useCallback(async (srcUrl: string, name: string) => {
  //   try {
  //     const response = await fetch(srcUrl, { method: 'GET' });
  
  //     if (!response.ok) {
  //       throw new Error(`Failed to fetch image. HTTP error! status: ${response.status}`);
  //     }
  
  //     const blob = await response.blob();
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = name;
  //     document.body.appendChild(a);
  //     a.click();
  
  //     setTimeout(() => {
  //       window.URL.revokeObjectURL(url);
  //       a.remove();
  //     }, 3000); // URL과 링크 제거를 위한 3초 타이머
  //   } catch (error) {
  //     console.error('Download error:', error);
  //   }
  // }, []);
  const onClickImgLink = useCallback((srcUrl: string,name : string) => {
    try {
      // 새로운 탭에서 파일 열기
      window.open(srcUrl, '_blank', 'noopener,noreferrer');
    } catch (error) {
      console.error('Failed to open the file:', error);
    }
  }, []);

  const createDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const dateStr = `${year}-${month}-${day}`;
    return dateStr;
  };

  const handleDownload = async (e: MouseEvent<HTMLButtonElement>) => {
    if (fileName) {
      try {
        await updateUserData(data);

        const url = `https://asset.soulx.co.kr/recordings/${fileName}`;
        const name = `Xroom_${createDate()}.mp4`;
        onClickImgLink(url, name);
      } catch (error) {
        console.log(error);
      }
    } else {
      alert('Recorded file not found');
    }
  };

  return (
    <Flex align={'center'} justify="center" minH={'100vh'}>
      <Card
        direction={{ base: 'column', lg: 'row' }}
        maxW={{ base: '80%', lg: '60%' }}
        overflow="hidden"
      >
        <Image
          objectFit={{ base: 'contain', lg: 'cover' }}
          maxW={{ base: '100%', lg: '40%' }}
          src={preDownloadImg}
          alt="xroom image"
          // objectPosition={{ lg: '60% top' }}
        />
        <Stack maxW={{ base: '100%', lg: '60%' }}>
          <CardBody padding={10}>
            <VStack align={'center'} gap={4}>
              <VStack align={'flex-start'}>
                <a
                  href="https://soulx.co.kr/xroom_sxsw"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Image src={newXroomLogo} alt="xroom logo" w={100} />
                </a>
                <Text fontSize={28} fontWeight="bold">
                  We’ve got your XR video!
                </Text>
                <Text fontSize={14}>
                  Your personal 3D video created with XRoom® is ready, and we
                  hope you like it! Simply enter some information below and
                  click the button to see yourself in virtual space.
                </Text>
              </VStack>
              <VStack w={'full'} gap={2} color={'white'}>
                <FormControl variant="floating" isRequired>
                  <Input
                    name="company"
                    color={'black'}
                    value={data.company}
                    onChange={handleInputVal}
                  />
                  <FormLabel>company</FormLabel>
                </FormControl>
                <FormControl variant="floating" isRequired>
                  <Input
                    name="name"
                    value={data.name}
                    color={'black'}
                    onChange={handleInputVal}
                  />
                  <FormLabel>name</FormLabel>
                </FormControl>
                <FormControl variant="floating" isRequired>
                  <Input
                    name="email"
                    value={data.email}
                    color={'black'}
                    onChange={handleInputVal}
                  />
                  <FormLabel>email</FormLabel>
                </FormControl>

                <Button
                  isDisabled={!(data.company && data.name && data.email)}
                  onClick={handleDownload}
                  colorScheme="blue"
                  style={{
                    backgroundColor: 'black',
                  }}
                >
                  View your video
                </Button>
              </VStack>
              <VStack align={'center'} justify="center" gap={2}>
                <Text fontSize={12} align="center">
                  If you'd like to learn more about XRoom®,
                  <br /> please contact Dawn at SoulX via LinkedIn below 🙂
                </Text>
                <HStack align={'center'} gap={10}>
                  <a
                    href="https://soulx.co.kr/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Image
                      objectFit="cover"
                      // maxW={{ base: '100%', lg: '40%' }}
                      maxH={{ base: '30px' }}
                      src={SoulxCILogo}
                      alt="xroom logo"
                    />
                  </a>
                  <a
                    href="https://soulx.co.kr/xroom_sxsw"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Image
                      objectFit="cover"
                      // maxW={{ base: '100%', lg: '40%' }}
                      maxH={{ base: '30px' }}
                      src={XroomIcon}
                      alt="xroom symbol"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/dawn-yun-8450a065/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Image
                      objectFit="cover"
                      // maxW={{ base: '100%', lg: '40%' }}
                      maxH={{ base: '30px' }}
                      src={LinkedIn}
                      alt="xroom logo"
                    />
                  </a>
                </HStack>
              </VStack>
            </VStack>
          </CardBody>
        </Stack>
      </Card>
    </Flex>
  );
};

export default PreDownload;
