import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { ChakraProvider, createLocalStorageManager } from '@chakra-ui/react';
import Main from 'pages/soulx/main';
import XroomSXSW from 'pages/soulx/XroomSXSW';

import ScrollToTop from 'components/scrolltotop';
import RouteChangeTracker from 'components/routeChangeTracker';
import { JazzbarProvider } from 'lib/jazzbar';
import ConditionalBackground from 'components/base/ConditionalBackground';
import { theme } from './lib/styles/chakraTheme';
import './typography.css'; // 이미 있는 임포트
import CESPage from 'pages/XROOM/CESPage';
import PreDownload from 'pages/soulx/PreDownload';
import { Provider } from 'react-redux';
import store from './store';
import ConcentricCirclesPage from 'MINIXAM/ConcentricCirclesPage';

const manager = createLocalStorageManager('my-key');

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.woff2') format('woff2'),
         url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    cross-origin: anonymous;
  }

  body {
    font-family: 'Pretendard', sans-serif;
  }
`;


const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8f9fa;
`;

const App: React.FC = () => {
  const [language, setLanguage] = useState<string>('EN'); // Default language

  return (

    <ChakraProvider theme={theme} colorModeManager={manager} >
      <Provider store={store}>
      <JazzbarProvider>
        <Helmet>
          <title>Soulx</title>
          <meta name="description" content="안녕하세요 소울 엑스 입니다." />
          <meta property="og:image" content="https://images.soulx.co.kr/soulx.png" />
          <meta property="og:title" content="Soulx" />
          <meta property="og:description" content="소울 엑스의 세계에 오신 것을 환영합니다!" />
          <meta property="og:url" content="https://www.soulx.co.kr/" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Helmet>
          <GlobalStyle/>
          <ConditionalBackground />
          <RouteChangeTracker />
          <ScrollToTop />
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/XROOM/*" element={<XroomSXSW language={language} />} />
              <Route path="/ces" element={<CESPage/>}/>
              <Route path="/pre-download" element={<PreDownload />} />
              <Route path="/ConcentricCircles" element={<ConcentricCirclesPage />} />
            </Routes>
      </JazzbarProvider>
      </Provider>
    </ChakraProvider>
  
  );
};
export default App;
