import apiClient from './apiClient';

interface IReqData {
  company: string;
  name: string;
  email: string;
}

export const updateUserData = async (reqData: IReqData) => {
  const res = await apiClient.post('/qrcode/scan', reqData);
  console.log(res);
  return res.data;
};
