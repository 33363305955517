import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentAudioVolume, setCurrentAudioPitch } from '../store/concentricCirclesSlice';

// Enhanced frequency to pitch mapping with more detailed note representation
const frequencyToPitch = (frequency: number): { note: string; pitch: string; octave: number } => {
  if (frequency < 16.35 || frequency > 7902.13) {
    return { note: 'N/A', pitch: 'N/A', octave: 0 };
  }

  const A4_FREQUENCY = 440; // 기준 주파수 A4
  const SEMITONE_DISTANCE = Math.round(12 * Math.log2(frequency / A4_FREQUENCY)); // 반음 거리
  const MIDI_NUMBER = 69 + SEMITONE_DISTANCE; // A4 기준 MIDI 번호는 69
  const NOTE_INDEX = (MIDI_NUMBER % 12 + 12) % 12; // 음수 방지
  const OCTAVE = Math.floor(MIDI_NUMBER / 12) - 1;

  const CHROMATIC_NOTES = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
  const note = CHROMATIC_NOTES[NOTE_INDEX];

  return { note, pitch: note, octave: OCTAVE };
};

// Custom hook for audio input
const useAudioInput = () => {
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserNodeRef = useRef<AnalyserNode | null>(null);
  const microphoneRef = useRef<MediaStreamAudioSourceNode | null>(null);
  const bandPassFilterRef = useRef<BiquadFilterNode | null>(null);
  const dispatch = useDispatch();

  const HUMAN_VOICE_LOW = 70;   // 인간 목소리 하한
  const HUMAN_VOICE_HIGH = 500; // 인간 목소리 상한

  // 주파수를 확인하고 인간 음성 범위만 필터링
  const calculateFrequency = (dataArray: Float32Array): number => {
    const nyquist = audioContextRef.current!.sampleRate / 2;
    const binSize = nyquist / dataArray.length;

    let maxIndex = 0;
    let maxMagnitude = -Infinity;

    for (let i = 0; i < dataArray.length; i++) {
      if (dataArray[i] > maxMagnitude) {
        maxMagnitude = dataArray[i];
        maxIndex = i;
      }
    }

    if (maxMagnitude < -70) return 0; // 노이즈 무시

    const frequency = maxIndex * binSize;

    // 인간 음성 범위만 허용
    return frequency >= HUMAN_VOICE_LOW && frequency <= HUMAN_VOICE_HIGH ? frequency : 0;
  };

  const calculateRMS = (dataArray: Float32Array): number => {
    let sum = 0;
  
    // 모든 값 포함 (제한 제거)
    for (let i = 0; i < dataArray.length; i++) {
      const linearValue = Math.pow(10, dataArray[i] / 20);
      sum += linearValue * linearValue;
    }
  
    const rms = Math.sqrt(sum / dataArray.length); // RMS 계산
    return rms*1000
  };

  useEffect(() => {
    const setupAudio = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        audioContextRef.current = new AudioContext();
        analyserNodeRef.current = audioContextRef.current.createAnalyser();
        bandPassFilterRef.current = audioContextRef.current.createBiquadFilter();

        bandPassFilterRef.current.type = 'bandpass';
        bandPassFilterRef.current.frequency.value = 250; // 인간 목소리 중심 주파수
        bandPassFilterRef.current.Q.value = 1; // 대역폭 조정

        microphoneRef.current = audioContextRef.current.createMediaStreamSource(stream);
        microphoneRef.current.connect(bandPassFilterRef.current);
        bandPassFilterRef.current.connect(analyserNodeRef.current);

        analyserNodeRef.current.fftSize = 4096;
        const bufferLength = analyserNodeRef.current.frequencyBinCount;
        const dataArray = new Float32Array(bufferLength);

        let lastUpdateTime = 0;
        const updateAudioData = (currentTime: number) => {
          if (currentTime - lastUpdateTime > 50) {
            analyserNodeRef.current?.getFloatFrequencyData(dataArray);

            const rms = calculateRMS(dataArray);
            const VOLUME_THRESHOLD = 0.2;

            if (rms > VOLUME_THRESHOLD) {
              const frequency = calculateFrequency(dataArray);

              if (frequency) {
                const pitchInfo = frequencyToPitch(frequency);
                if (pitchInfo.note !== 'N/A') {
                  dispatch(setCurrentAudioVolume(rms));
                  dispatch(setCurrentAudioPitch(`${pitchInfo.note}${pitchInfo.octave}`));
                }
              }
            } else {
              dispatch(setCurrentAudioPitch('N/A'));
            }

            lastUpdateTime = currentTime;
          }
          requestAnimationFrame(updateAudioData);
        };

        requestAnimationFrame(updateAudioData);
      } catch (err) {
        console.error('Microphone access failed:', err);
      }
    };

    setupAudio();

    return () => {
      if (microphoneRef.current) microphoneRef.current.disconnect();
      if (audioContextRef.current) audioContextRef.current.close();
    };
  }, [dispatch]);

  return null;
};

export default useAudioInput;