import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentMp3Volume, setCurrentMp3Pitch } from '../store/concentricCirclesSlice';

// Frequency to pitch mapping function
const frequencyToPitch = (frequency: number): { note: string; pitch: string; octave: number } => {
  if (frequency < 16.35 || frequency > 7902.13) {
    return { note: 'N/A', pitch: 'N/A', octave: 0 };
  }

  const A4_FREQUENCY = 440;
  const SEMITONE_DISTANCE = Math.round(12 * Math.log2(frequency / A4_FREQUENCY));
  const MIDI_NUMBER = 69 + SEMITONE_DISTANCE;
  const NOTE_INDEX = (MIDI_NUMBER % 12 + 12) % 12;
  const OCTAVE = Math.floor(MIDI_NUMBER / 12) - 1;

  const CHROMATIC_NOTES = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
  const note = CHROMATIC_NOTES[NOTE_INDEX];

  return { note, pitch: note, octave: OCTAVE };
};

// Custom hook for MP3 input
const useMp3Input = () => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserNodeRef = useRef<AnalyserNode | null>(null);
  const sourceNodeRef = useRef<MediaElementAudioSourceNode | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const setupAudio = async () => {
      const audioElement = document.createElement('audio');
      audioElement.src = './models/cont.mp3';
      audioElement.crossOrigin = 'anonymous';
      audioElement.load();
      audioRef.current = audioElement;

      // Play the audio after it is loaded
      audioElement.oncanplay = async () => {
        try {
          await audioElement.play();
        } catch (error) {
          console.error('Failed to play audio:', error);
        }
      };

      if (!audioContextRef.current) {
        audioContextRef.current = new AudioContext();
        analyserNodeRef.current = audioContextRef.current.createAnalyser();

        sourceNodeRef.current = audioContextRef.current.createMediaElementSource(audioElement);
        sourceNodeRef.current.connect(analyserNodeRef.current);
        analyserNodeRef.current.connect(audioContextRef.current.destination);

        analyserNodeRef.current.fftSize = 4096;
        const bufferLength = analyserNodeRef.current.frequencyBinCount;
        const dataArray = new Float32Array(bufferLength);

        const calculateFrequency = (dataArray: Float32Array): number => {
          const nyquist = audioContextRef.current!.sampleRate / 2;
          const binSize = nyquist / dataArray.length;

          let maxIndex = 0;
          let maxMagnitude = -Infinity;

          for (let i = 0; i < dataArray.length; i++) {
            if (dataArray[i] > maxMagnitude) {
              maxMagnitude = dataArray[i];
              maxIndex = i;
            }
          }

          if (maxMagnitude < -70) return 0;

          const frequency = maxIndex * binSize;
          return frequency;
        };

        const updateAudioData = () => {
          analyserNodeRef.current?.getFloatFrequencyData(dataArray);

          const frequency = calculateFrequency(dataArray);
          if (frequency) {
            const pitchInfo = frequencyToPitch(frequency);
            if (pitchInfo.note !== 'N/A') {
              dispatch(setCurrentMp3Pitch(`${pitchInfo.note}${pitchInfo.octave}`));
            }
          } else {
            dispatch(setCurrentMp3Pitch('N/A'));
          }

          requestAnimationFrame(updateAudioData);
        };

        requestAnimationFrame(updateAudioData);
      }
    };

    setupAudio();

    return () => {
      audioRef.current?.pause();
      if (audioContextRef.current) audioContextRef.current.close();
    };
  }, [dispatch]);

  return null;
};

export default useMp3Input;