import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import styled from 'styled-components';
import useMp3Input from 'hooks/useMp3Input';

const NOTE_POSITIONS: Record<string, number> = {
  'C': 50, 'C#': 45, 'D': 40, 'D#': 35, 'E': 30,
  'F': 25, 'F#': 20, 'G': 15, 'G#': 10, 'A': 5, 'A#': 0, 'B': -5,
};

const Mp3Visualizer = () => {
  useMp3Input();

  const { currentMp3Pitch = 'N/A' } = useSelector(
    (state: RootState) => state.concentricCircles
  );
  const { currentAudioPitch = 'N/A' } = useSelector(
    (state: RootState) => state.concentricCircles
  );

  const [notes, setNotes] = useState<{ note: string; octave: number }[]>([]);
  const [currentNoteIndex, setCurrentNoteIndex] = useState<number>(-1);
  const [isMatch, setIsMatch] = useState<boolean>(false);

  useEffect(() => {
    if (currentMp3Pitch !== 'N/A') {
      const match = currentMp3Pitch.match(/([A-G]#?)(\d+)/);
      if (match) {
        const [, note, octave] = match;
        setNotes((prev) => {
          const updatedNotes = [...prev, { note, octave: parseInt(octave, 10) }];
          setCurrentNoteIndex(updatedNotes.length - 1);
          return updatedNotes;
        });
      }
    }
  }, [currentMp3Pitch]);

  useEffect(() => {
    if (currentAudioPitch !== 'N/A' && currentMp3Pitch !== 'N/A') {
      const matchAudio = currentAudioPitch.match(/([A-G]#?)(\d+)/);
      const matchMp3 = currentMp3Pitch.match(/([A-G]#?)(\d+)/);

      if (matchAudio && matchMp3) {
        const [, audioNote, audioOctave] = matchAudio;
        const [, mp3Note, mp3Octave] = matchMp3;
        setIsMatch(audioNote === mp3Note && audioOctave === mp3Octave);
      } else {
        setIsMatch(false);
      }
    }
  }, [currentAudioPitch, currentMp3Pitch]);

  return (
    <VisualizerContainer>
      <Stave>
        {[0, 20, 40, 60, 80].map((y) => (
          <StaveLine key={y} top={y} />
        ))}
      </Stave>

      <NotesContainer>
        {notes.map((note, index) => {
          const basePosition = NOTE_POSITIONS[note.note] ?? 50; // 기본 위치 추가
          const octaveOffset = (note.octave - 4) * 30;
          const isCurrent = index === currentNoteIndex;

          return (
            <Note
              key={index}
              style={{
                top: `${basePosition - octaveOffset}px`,
                left: `${index * 25}px`,
                backgroundColor: isCurrent ? (isMatch ? 'green' : 'red') : 'black',
              }}
            />
          );
        })}
      </NotesContainer>

      <Needle
        style={{
          left: `${currentNoteIndex * 25}px`,
        }}
      />

      {currentAudioPitch !== 'N/A' && (
        <VoiceNote
          style={{
            top: `${
              NOTE_POSITIONS[currentAudioPitch.match(/([A-G]#?)/)?.[1] || 'C'] -
              (parseInt(currentAudioPitch.match(/\d+/)?.[0] || '4') - 4) * 30
            }px`,
          }}
        >
          <NoteHead style={{ backgroundColor: 'blue' }} />
        </VoiceNote>
      )}

      <CurrentNoteDisplay>
        <div>
          <strong>Current MP3 Pitch:</strong> {currentMp3Pitch || 'N/A'}
        </div>
        <div>
          <strong>Current Audio Pitch:</strong> {currentAudioPitch || 'N/A'}
        </div>
        <div>
          <strong>Match:</strong> {isMatch ? 'Yes' : 'No'}
        </div>
      </CurrentNoteDisplay>
    </VisualizerContainer>
  );
};

const VisualizerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  margin: 20px auto;
  border: 1px solid black;
  overflow-x: hidden;
  background: white;
`;

const Stave = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const StaveLine = styled.div<{ top: number }>`
  position: absolute;
  top: ${(props) => props.top}px;
  width: 100%;
  height: 1px;
  background-color: black;
`;

const Needle = styled.div`
  position: absolute;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: red;
  z-index: 10;
`;

const NotesContainer = styled.div`
  position: absolute;
  top: 0;
  left: 10px;
  display: flex;
  align-items: center;
`;

const Note = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

const VoiceNote = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
`;

const NoteHead = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

const CurrentNoteDisplay = styled.div`
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
`;

export default Mp3Visualizer;