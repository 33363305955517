import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import styled from 'styled-components';

const NOTE_POSITIONS: Record<string, number> = {
  'C': 50, 'C#': 45, 'D': 40, 'D#': 35, 'E': 30,
  'F': 25, 'F#': 20, 'G': 15, 'G#': 10, 'A': 5, 'A#': 0, 'B': -5,
};

const MAX_NOTES = 20;

const MusicSheet = () => {
  const { currentAudioPitch: currentPitch = '' } = useSelector(
    (state: RootState) => state.concentricCircles
  );
  const [notes, setNotes] = useState<{ note: string; octave: number }[]>([]);
  const [currentNote, setCurrentNote] = useState<string>('');
  const [currentNoteIndex, setCurrentNoteIndex] = useState<number>(-1);

  useEffect(() => {
    if (currentPitch !== 'N/A') {
      const match = currentPitch.match(/([A-G]#?)(\d+)/);
      if (match) {
        const [, note, octave] = match;
        setNotes((prev) => {
          const updatedNotes = [...prev, { note, octave: parseInt(octave, 10) }];
          setCurrentNoteIndex(updatedNotes.length - 1); // 현재 노트 인덱스 설정
          return updatedNotes.slice(-MAX_NOTES);
        });
        setCurrentNote(`${note}${octave}`); // 현재 감지된 음표 표시
      }
    }
  }, [currentPitch]);

  return (
    <SheetContainer>
      <Stave>
        {[0, 20, 40, 60, 80].map((y) => (
          <StaveLine key={y} top={y} />
        ))}
      </Stave>

      <NotesContainer>
        {notes.map((note, index) => {
          const basePosition = NOTE_POSITIONS[note.note] || 0; // 음표의 기본 위치
          const octaveOffset = (note.octave - 4) * 30; // 옥타브별 위치 보정값
          const isSharp = note.note.includes('#');

          return (
            <Note
              key={index}
              style={{
                top: `${basePosition - octaveOffset}px`,
                left: `${index * 25}px`,
              }}
            >
              {isSharp && <Sharp>#</Sharp>}
              <NoteHead />
            </Note>
          );
        })}
      </NotesContainer>

      {/* 바늘 */}
      <Needle
        style={{
          left: `${currentNoteIndex * 25}px`,
        }}
      />

      <CurrentNoteDisplay>
        Current Note: {currentNote || 'No note detected'}
      </CurrentNoteDisplay>
    </SheetContainer>
  );
};

const SheetContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100px; /* 오선지 높이 조정 */
  margin: 20px auto;
  border: 1px solid black;
  overflow-x: auto;
  background: white;
`;

const Stave = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const StaveLine = styled.div<{ top: number }>`
  position: absolute;
  top: ${(props) => props.top}px;
  width: 100%;
  height: 1px;
  background-color: black;
`;

const NotesContainer = styled.div`
  position: absolute;
  top: 0;
  left: 10px;
  display: flex;
  align-items: center;
`;

const Note = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
`;

const NoteHead = styled.div`
  width: 10px; /* 너비를 줄임 */
  height: 8px; /* 높이를 줄임 */
  background-color: black;
  border-radius: 50%;
  transform: rotate(-20deg);
`;

const Sharp = styled.div`
  position: absolute;
  left: -10px;
  top: -2px;
  font-size: 14px;
  font-weight: bold;
  color: black;
`;

const Needle = styled.div`
  position: absolute;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: red;
  z-index: 10;
  transition: left 0.1s linear; /* 부드럽게 이동 */
`;

const CurrentNoteDisplay = styled.div`
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #333;
`;

export default MusicSheet;